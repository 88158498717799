<template>
  <div class="login-container">
    <div class="login-box">
      <div class="login-box-left"></div>
      <keep-alive>
      <router-view></router-view>
      </keep-alive>
    </div>

    <div class="beian-box">
        备案号:
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021006184号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  mounted() {
    if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
      //显示移动端的代码
      console.log('移动端')
      this.$router.push('/loginMoble')
      }else{
      //显示非移动端的代码
      console.log('pc端')
      this.$router.push('/login/qrcode')
    }
    
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.login-container {
  position: relative;
  height: 100vh;
  background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;

  .beian-box{
      height: 20px;
      position: absolute;
      left: 50%;
      bottom: 20px;
      z-index: 999;
      transform: translateX(-50%);
      color: #ddd;
      font-size: 12px;
      a{
          color: #ddd;
      }
  }

  .login-box {
    position: absolute;
    width: 1200px;
    height: 680px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 2px 1px 3px 0px rgba(255, 255, 255, 0.7),
      -2px -2px 6px 0px rgba(24, 83, 155, 0.78),
      -1px -1px 3px 0px rgba(10, 77, 151, 0.78),
      18px 9px 250px 0px rgba(255, 255, 255, 0.44);
    border-radius: 15px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/login-box.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    .login-box-left {
      width: 50%;
      height: 680px;
      background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/login-left.png);
      background-repeat: no-repeat;
      background-size: 100% 675px;
      background-position: center;
      margin-left: 3px;
      margin-top: 1px;
    }
    .login-box-right {
      width: 50%;
      height: 680px;
      position: relative;
      .title-box {
        font-size: 20px;
        font-weight: bold;
        color: #199aec;
        text-align: center;
        margin-top: 15px;
        .iconfont {
          line-height: 26px;
        }
      }
      .title-container {
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 50px;
        text-align: center;
      }
      .qrcode-box {
        width: 350px;
        height: 350px;
        margin: 25px auto;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/qrode-box.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tips-box {
        width: 370px;
        height: 47px;
        margin: 0 auto;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/tips.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
      }
      .mei-left {
        width: 200px;
        height: 220px;
        position: absolute;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-left.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        bottom: -40px;
        left: -70px;
      }
      .mei-right {
        width: 200px;
        height: 207px;
        position: absolute;
        background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/mei-right.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        bottom: -40px;
        right: -70px;
      }

      .login-form {
        margin: 66px auto;
        max-width: 300px;
        .login-form-forgot {
          float: right;
        }
        .login-form-button {
          width: 100%;
        }
        /deep/.ant-checkbox-wrapper {
          color: #1890ff;
        }
      }
    }
  }
}

@media screen and(max-width: 750px) {
  .login-container {
      .login-box {
        width: 100%;
        .login-box-left {
          display: none;
        }
        .login-box-right{
          margin: 0 auto;
          width: 80%;
        }
      }
  }
}


</style>
